import React from 'react';
import './Gallery.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
// Import 29 hình ảnh
import imgOne from '../../assets/servicePage/gallery/1.jpg';
import imgTwo from '../../assets/servicePage/gallery/2.jpg';
import imgThree from '../../assets/servicePage/gallery/3.jpg';
import imgFour from '../../assets/servicePage/gallery/4.jpg';
import imgFive from '../../assets/servicePage/gallery/5.jpg';
import imgSix from '../../assets/servicePage/gallery/6.jpg';
import imgSeven from '../../assets/servicePage/gallery/7.jpg';
import imgEight from '../../assets/servicePage/gallery/8.jpg';
import imgNine from '../../assets/servicePage/gallery/9.jpg';
import imgTen from '../../assets/servicePage/gallery/10.jpg';
import imgEleven from '../../assets/servicePage/gallery/11.jpg';
import imgTwelve from '../../assets/servicePage/gallery/12.jpg';
import imgThirteen from '../../assets/servicePage/gallery/13.jpg';
import imgFourteen from '../../assets/servicePage/gallery/14.jpg';
import imgFifteen from '../../assets/servicePage/gallery/15.jpg';
import imgSixteen from '../../assets/servicePage/gallery/16.jpg';
import imgSeventeen from '../../assets/servicePage/gallery/17.jpg';
import imgEighteen from '../../assets/servicePage/gallery/18.jpg';
import imgNineteen from '../../assets/servicePage/gallery/19.jpg';
import imgTwenty from '../../assets/servicePage/gallery/20.jpg';
import imgTwentyOne from '../../assets/servicePage/gallery/21.jpg';
import imgTwentyTwo from '../../assets/servicePage/gallery/22.jpg';
import imgTwentyThree from '../../assets/servicePage/gallery/23.jpg';
import imgTwentyFour from '../../assets/servicePage/gallery/24.jpg';
import imgTwentyFive from '../../assets/servicePage/gallery/25.jpg';
import imgTwentySix from '../../assets/servicePage/gallery/26.jpg';
import imgTwentySeven from '../../assets/servicePage/gallery/27.jpg';
import imgTwentyEight from '../../assets/servicePage/gallery/28.jpg';
import imgTwentyNine from '../../assets/servicePage/gallery/29.jpg';
import imgThirty from '../../assets/servicePage/gallery/30.jpg';
import imgThirtyOne from '../../assets/servicePage/gallery/31.jpg';


const Gallery = () => {
    return (
        <section className="gallery-section">
            <div className="container">
                <SectionTitle
                    subTitle="HÌNH ẢNH"
                    title="TỪNG KHOẢNH KHẮC TỪNG NỤ CƯỜI HOÀN HẢO"
                    description="Hãy cùng khám phá những hình ảnh ghi lại quá trình thực hiện dịch vụ của chúng tôi. Mỗi khoảnh khắc đều thể hiện sự tận tâm và cam kết mang đến trải nghiệm tốt nhất cho khách hàng. Đây là cách chúng tôi xây dựng niềm tin và khẳng định chất lượng trong từng chi tiết"
                />
                <div className="gallery-grid">
                    {/* Render 29 hình ảnh */}
                    <div className="gallery-img"><img src={imgOne} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwo} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgThree} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgFour} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgFive} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgSix} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgSeven} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgEight} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgNine} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgEleven} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwelve} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgThirteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgFourteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgFifteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgSixteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgSeventeen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgEighteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgNineteen} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwenty} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyOne} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyTwo} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyThree} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyFour} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyFive} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentySix} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentySeven} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyEight} alt="gallery" /></div>
                    <div className="gallery-img"><img src={imgTwentyNine} alt="gallery 2" /></div>
                    <div className="gallery-img"><img src={imgThirty} alt="gallery 3" /></div>
                    <div className="gallery-img"><img src={imgThirtyOne} alt="gallery 4" /></div>
                </div>
            </div>
        </section>
    );
};

export default Gallery;
